import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import axios from 'axios';

const FooterComponent = ({ links }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form is submitted
    try {
      const response = await axios.post('https://connect.mailerlite.com/api/subscribers', {
        email: email,
        group: "144360589993445123"
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMDIwYjY0NDE0NDFkNjlkMmQ1ZmEwMTM1OTQ2NzAxODA3MGNkMTA5YTk1MWJkMzRiZWMwMTQ4NzcxNWFmY2MxMzZjOTI5ZDZiOWI0NjYxNTkiLCJpYXQiOjE3Mzc2ODU4NTMuNDE4Nzk5LCJuYmYiOjE3Mzc2ODU4NTMuNDE4ODAyLCJleHAiOjQ4OTMzNTk0NTMuNDE0MDkxLCJzdWIiOiIxMzA2MzI5Iiwic2NvcGVzIjpbXX0.f2ZlNMGXq4ZPoOTC6006QfOAvcEKc5-7gC6kOrwyY-yAOBMnM_penoqegzM-8KmcKYlyocnY3jsjcGg2nrS1hJw6HndckCVw_J_9nj_jBTaK6AcL_V34aECuH-2XRmeLfFFOiXxgD-ViV68zz4iHTUbrDT9gJc8V_sWcVbxHHzgUZXMHG085UoK3sfIXVEAu0dluSc3i2ISNthCdkTSTaMDFsm7xE2x7pglA0XNfg8O8IEdNVABmhBP8cF0CCv743PcqYPwFixOubrrIPvRpy_6w-TQS-uW5vNUE0d6PtSMAhTF5qK0XxFQ3NcoxFGNSn5nmVHiyquQQ6_rQftTisGzua5queeqoffV_X-xAOOpXTz8Dc7SAtJ7n8QxhRcuThNhxBqCIz1Zg9KOOfCRcRM9qK0kt5_Tn-vQzVbfSChzEW4BUdXxehCHB6owMxi27kVAFZ8l46jy15r8XjZtov--iuyhk4JFdw4sYDD-DD1SYZOJlXzfjzZINrDVMhRfjjZFdPVi_fIIlh9hiMjo91LutDpSQtaQhz3-q8J2DO08ZK9bbXGzUO2qP7g0FkrOU3rPpM6NcdbrqbpP7FmwE-zJjw6jDmcxojAS_pZ5riTjUkiSeedJGiKooAQV8XbR7KtxuG2ItxalDKjdjlViCWkAsCRe_jKBgDC4p1qf5sXo'        }
      });
      if (response.status === 200) {
        setMessage('Subscription successful!');
      } else {
        setMessage('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error subscribing:', error);
      setMessage('Subscription failed. Please try again.');
    } finally {
      setLoading(false); // Set loading to false when response is received
    }
  };

  return (
    <Container className='mb-4 pt-3'>
      <Row className="align-items-center mb-4">
        <Col xs={12} md={6} className='d-flex justify-content-md-end justify-content-center align-items-center mb-3 mb-md-0'>
          <p className="subscribe glow mb-0">{message || "Subscribe to our newsletter for business growth ideas."}</p>
        </Col>
        <Col xs={12} md={4} className='d-flex justify-content-md-start justify-content-center align-items-center'>
          <Form className="w-100" onSubmit={handleSubmit}>
            <InputGroup>
              <Form.Control
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={loading ? 'loading' : ''} // Add loading class conditionally
                autoComplete="email" // Add this line for email autofill suggestions
              />
              <Button type="submit" className='glow' disabled={loading}>Subscribe</Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>
      <div className="navbar navbar-expand navbar-dark bg-dark p-0">
        <ul className="navbar-nav m-auto">
          {links.map((link, i) => (
            <React.Fragment key={i}>
              <li className="nav-item">
                <NavLink className='nav-link' to={link.to} end>
                  {link.name}
                </NavLink>
              </li>
              {i < links.length - 1 && <li className="nav-item"><span className='slashes'>//</span></li>}
            </React.Fragment>
          ))}
        </ul>
      </div>
      <p className='text-muted text-center'>&#169; {(new Date().getFullYear())} // All rights reserved.</p>
    </Container>
  )
}

export default FooterComponent;
