import React from "react";

export default function Mockup({ tilt=false, title = "", image, height=310, rx=8 }) {
    return (
    <svg className={'mockup ' + (tilt ? 'tilt': '')} xmlns="http://www.w3.org/2000/svg" width="100%" height={height} preserveAspectRatio="xMinYMax meet" version="1.1">
        <defs>
            <rect id="rect" width="100%" height={height} rx={rx}/>
            <clipPath id="clip">
            <use href="#rect"/>
            </clipPath>
            
            <filter id="blur" x="-50%" y="-50%" width="200%" height="200%">
                <feGaussianBlur stdDeviation="5" />
            </filter>
        </defs>
        <g id="CLIENT" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="rotate3d(1, 1, 1, 45deg)">
            <g id="client">
                <rect id="Rectangle-5" fill="#000" x="0" y="0" width="100%" height="40" rx={rx} transform="rotate3d(1, 1, 1, 45deg)"/>
                <image href={`${image}`} y="30" width="100%" alt={title} clip-path="url(#clip)"></image><g id="dots" transform="translate(12.000000, 12.000000)">
                <g id="blurdots" transform="translate(12.000000, 12.000000)"></g>
                    <circle id="red" fill="#DF4E47" cx="4" cy="4" r="6"/>
                    <circle id="yellow" fill="#D1A839" cx="24" cy="4" r="6"/>
                    <circle id="green" fill="#34B18F" cx="44" cy="4" r="6"/>
                </g>
                <g id="dots" transform="translate(16.000000, 16.000000)">
                    <circle id="red" fill="#DF4E47" r="6" filter="url(#blur)"/>
                    <circle id="yellow" fill="#D1A839" cx="20" r="6" filter="url(#blur)"/>
                    <circle id="green" fill="#34B18F" cx="40" r="6" filter="url(#blur)"/>
                    <text x="72" y="5" fill='white'>{title}</text>
                </g>
            </g>
        </g>
    </svg>
    );
}