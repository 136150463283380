import React from 'react';
import { NavLink } from "react-router-dom"
import { Container, Navbar, Row, Col } from 'react-bootstrap'

const NavbarComponent = ({links, socials}) => {
  const expand = false;
  return (
    <div>
      <Navbar key={expand} bg="dark" varient="dark" className='navbar-dark' expand={expand}>
        <Container fluid>
          <NavLink className="navbar-brand" to="/">
            <svg className="m-2" width="50px" height="50px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <linearGradient gradientTransform="rotate(25)" id="gradient" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="0%" stopColor="#574851" stopOpacity="1"></stop><stop data-v-fde0c5aa="" offset="100%" stopColor="#95A8B1" stopOpacity="1"></stop></linearGradient>
              </defs>
              <g clipRule="evenodd" fill="url(#gradient)"><path d="M17.084 2.356c.053.006.976.369 1.104.663.177.405.144 1.758.221 2.578 2.323 24.724 4.529 48.322 6.922 73.049.129 1.336.759 4.242.294 4.934-.295.438-3.026 1.212-3.977.662-.648-.374-.864-2.481-1.104-3.534a506.255 506.255 0 0 1-5.081-24.963c-.058-.328-.199-1.771-.368-1.915-.491-.416-1.515 1.252-1.62 1.547-.789 2.209-1.434 5.528-1.988 7.879-2.37 10.048-4.48 19.13-6.848 29.234-.253 1.081-.676 1.955-.294 2.798.636 1.403 4.122 1.039 6.48.957 6.589-.228 11.766-.285 18.188-.589 1.389-.065 3.082-.372 3.535.074.426.419.424 1.796-.074 2.135-.593.404-2.602.147-3.608.147-6.346 0-11.939.147-18.336.147-2.648 0-7.259.749-8.174-1.179-.634-1.335.208-3.981.589-5.596a2913.665 2913.665 0 0 1 7.732-31.959c.73-2.94 1.064-5.448 3.093-6.849.408-.281.97-.464 1.031-.662.105-.345-.398-1.951-.516-2.725-.974-6.403-1.187-14.074-.884-21.502.147-3.585.055-8.748.221-12.519.078-1.762.074-3.911.074-6.407 0-1.647-.241-5.138.368-5.891.375-.461 2.057-.629 3.02-.514zm.81 24.301c-.378.371.085 2.658.147 3.387.639 7.53 1.272 14.987 1.915 22.46.008.093.1 1.463.368 1.546.144.045.526-.207.589-.368.054-.139-.058-.702-.074-.884-.737-8.452-1.434-16.077-2.356-24.447-.082-.746-.1-1.76-.442-1.694-.027.005-.128-.018-.147 0zm3.682 32.548c.139-.307-.477-3.132-.589-3.461-.033-.096-.506.129-.516.146-.101.2.104.718.147 1.031.122.874.213 1.673.368 2.577.101-.091.53-.161.59-.293zM54.787 90.942c4.725-.511 10.577 1.015 12.003 4.565.057.141.492 2.141.147 2.431-.21.176-2.023 0-2.578 0H48.895c-.648 0-2.353.162-2.577 0-.306-.221-.186-2.155.147-2.356.454-.274 3.435 0 4.124 0 .698 0 3.44.314 3.977-.074.35-.254.221-1.398.221-2.209v-2.357z"></path><path d="M53.682 94.624h-6.628c.996-1.813 4.029-3.052 6.407-3.534.485-.099.294 1.218.294 1.768 0 .715.056 1.315-.073 1.766z"></path></g>
            </svg>
            <p className='text-gradient d-inline fw-semibold'>Reavis Digital</p>
          </NavLink>
          <Navbar.Toggle id='navburger' aria-controls={`offcanvasNavbar-expand-${expand}`}>
            <svg className="mt-2 mb-2" width="20px" height="12px" viewBox="0 0 20 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="rgba(255, 255, 255, 0.75)">
                  <g>
                    <rect x="1" y="0" width="20" height="2"></rect>
                    <rect rx="1" x="6" y="5" width="14" height="2"></rect>
                    <rect rx="1" x="0" y="10" width="20" height="2"></rect>
                  </g>
                </g>
              </g>
            </svg>
          </Navbar.Toggle>
          <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end">
            <Navbar.Toggle className='me-0'>
            <div className='offcanvas-header bg-dark'>
              <span className="btn-close btn-close-white"></span>
            </div>
            </Navbar.Toggle>
            <div className="offcanvas-body bg-dark">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              {links.map((link, i) => (
                <li key={i} className="nav-item">
                  <Navbar.Toggle>
                    <NavLink className='nav-link' to={link.to} end>{link.name}</NavLink>
                  </Navbar.Toggle>
                </li>
              ))}
              </ul>
              <div className="navbar">
                <div className="navbar-nav flex-row list-inline">
                {socials.map((link, i) => (
                  <li key={i} className="nav-item list-inline-item pe-3">
                    <a className="nav-link link-light" target="_blank" rel="noreferrer" href={link.to}>{link.name}</a>
                  </li>
                ))}
                </div>
              </div>
            </div>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <div className="navbar navbar-expand-lg navbar-dark bg-dark">
        <Container fluid>
          {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <Row className="collapse navbar-collapse" id="navbarSupportedContent">
            <Col>
              <ul className="navbar-nav">
              {links.map((link, i) => (
                <li key={i} className="nav-item">
                  <NavLink className='nav-link' to={link.to} end>
                    {link.name}
                  </NavLink>
                </li>
              ))}
              </ul>
            </Col>
            <Col>
              <ul className="navbar-nav justify-content-end">
                {socials.map((link, i) => (
                  <li key={i} className="nav-item">
                    <a className="nav-link" target="_blank" rel="noreferrer" href={link.to}>{link.name}</a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <hr />
        </Container>
      </div>
    </div>
  )
}

export default NavbarComponent
