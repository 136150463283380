import React from 'react'
import { NavLink } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import TextTransition, { presets } from 'react-text-transition'
import Work from './Work'
import Counter from "../helpers/Counter"
import Mockup from "../helpers/Mockup"
import { FaChrome, FaSearchDollar, FaAd, FaGoogle } from 'react-icons/fa'

const TEXTS = ['renovation', 'roofing', 'plumbing', 'landscaping', 'flooring', 'remodeling', 'electrician', 'septic', 'HVAC', 'painting', 'ecommerce'];

const services = [
    { title: "Marketing", description: "Marketing your business online is more than just having a website - we'll take you all the way.", icon: <FaSearchDollar /> },
    { title: "Websites", description: "New or redesigned websites built to convert visitors into leads with our decade+ of experience.", icon: <FaChrome /> },
    { title: "Ads", description: "We'll advertise to your target audience to get your offer in front of people who need it.", icon: <FaAd /> },
    { title: "SEO", description: "Customers usually click one of the first results on Google Search. We want that result to be you.", icon: <FaGoogle /> },
];

const steps = [
  { title: "Evaluate", description: "We analyze what your business needs (and what it doesn't) to maximize effectiveness while staying in budget.", icon: "1" },
  { title: "Execute", description: "Next, we'll implement improvements to grow your business, establishing the foundation for forward momentum.", icon: "2" },
  { title: "Enhance", description: "But it doesn't stop there. The Internet is ever changing, so we continously edit your online presence to keep you cutting-edge.", icon: "3" },
];

function Home(props) {
    const [index, setIndex] = React.useState(0);
    React.useEffect(() => {
        const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
    return (
        <>
        <Row className='hero'>
          <Col>
            <span className="text-gradient-long">
              <h1 className='fs-1'>We deliver leads to <TextTransition inline={true} className='text-gradient-break' springConfig={presets.default}>{TEXTS[index % TEXTS.length]}</TextTransition> businesses <br className=' new-line-mobile' />like yours.</h1>
            </span>
            <h2 className='fw-light fs-4'>
              Our proven solution amplifies businesses using <span className='text-white'>digital marketing strategies </span> to make sure customers <span className='text-white'>find your business</span> instead of the competition. 
            </h2>
            <NavLink className='btn btn-light btn-main mt-4 glow' to="/contact">Free Consultation</NavLink>
          </Col>
        </Row>
        <Row className='section'>
          <Col>
            <p className='text-main'>
              <span className='text-white'>With Reavis Digital, you can expect more than just a web design studio. </span> 
              We build long-term relationships with our clients to supercharge their online marketing and bring in new leads every month.
            </p>
            <h4 className='text-highlight drop-shadow'>Our clients generate as much as <span className='text-white counter-span'>$<Counter number={20000} duration={2} />+</span> in estimated monthly revenue with us helping capture organic leads and sales using our SEO & web marketing strategies.</h4>
            <p className='text-main'><span className='text-white text-center'>Interested in fueling your business and gaining relevant leads online? </span>
            Whether your business is newly-founded or well-established... <span className='text-white text-center'>We can deliver new leads.</span></p>
            {/* <Mockup tilt={true} height={500} image="https://cdn.dribbble.com/userupload/15813490/file/original-fe8750451a2d04317cf2f4d351a3aa70.jpeg?resize=1200x900" /> */}
            </Col>
        </Row>
        <Row className='hero'>
          <Col xs={12} className='mb-4'><h2 className='text-gradient fw-bold'>Our 3-step process:</h2></Col>
            {steps.map((step, i) => (
              <Col key={i} xl={4} lg={4} className='d-flex align-items-stretch px-0 pb-1 mb-sm-5 mb-xs-5'>
                <Card className=''>
                  <Card.Body className='p-0'>
                    <Row>
                      <Col xs={1}><span className='big-num text-fade'>{step.icon}</span></Col>
                      <Col>
                        <Card.Title className='text-gradient fw-bold'>{step.title}</Card.Title>
                        <Card.Text>
                          {step.description}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            <NavLink className='btn btn-light btn-main mt-4 glow' to="/contact">Free Consultation</NavLink>
        </Row>
        <Row className='section'>
          <Col>
              <h2 className='section-label text-gradient'>Projects</h2>
              <Work Location="home" api={props.dribbbleAPI} />
            <div className='text-center'>
              <NavLink className='btn btn-dark btn-main' to="/work">View Our Portfolio</NavLink>
            </div>
          </Col>
        </Row>
        <Row className="hero">
          <Col>
            <h1 className='section-label text-gradient'>Work With Us</h1>
            <p className='lead'>
              Looking for an all-in-one web and marketing solution that can help take your business's online presence to the next level? 
              Look no further than our team at Reavis Digital!
            </p>
            <p>
              We specialize in creating beautiful, functional websites and marketing solutions for local businesses that are designed to help you achieve your goals and make your brand stand out in a crowded digital landscape. 
            </p>
            <p>
              If you're looking to grow your business and get new clients, with <span className="text-white">over a decade of experience</span> in the field, we have the expertise to deliver results.
            </p>
            <NavLink className='btn btn-light btn-main glow' to="/contact">Let's Talk</NavLink>
          </Col>
        </Row>
      </>
    );
  }
  
  export default Home;
  