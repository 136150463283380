import React, { useState, useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { FiArrowRight } from 'react-icons/fi';
import dateFormat from 'dateformat';
import { Link, useNavigate } from 'react-router-dom'

const Post = (props) => {
    const { title, link, thumbnail, content, excerpt, date, _links } = props.blogData;
    const [tagsData, setTagsData] = useState([])
    const [author, setAuthor] = useState([])
    const navigate = useNavigate();

    const toText = (html) => new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
    const cleanTitle = (title) => title.replace(/&#\d+;/g, '');
    
    useEffect(() => {
      fetch(_links.author[0].href)
          .then(response => response.json())
          .then(data => {
            setAuthor(data);
          })
          .catch(error => {
            console.error('Error fetching author:', error);
            setAuthor([]);
          });
  }, [_links.author]);

  useEffect(() => {
      const tagStr = tagsData.map(x => x).join(',');
      fetch(props.api + "tags?include=" + tagStr)
          .then(response => response.json())
          .then(data => {
            setTagsData(Array.isArray(data) ? data : []);
          })
          .catch(error => {
            console.error('Error fetching tags:', error);
            setTagsData([]);
          });
  }, [props.api]);

    return (
      <div className="post-content">
            <div className="mb-2 me-auto">
              <h1 className='text-gradient pb-1'>{title.rendered}</h1>
              <span className="small">By {author.name} | {dateFormat(date, "mm.d.yyyy")}</span>
            </div>
            
            <div dangerouslySetInnerHTML={{ __html: content.rendered }} />
            <div>{tagsData.map(tag => <span key={tag.id}>#{tag.slug} </span>)}</div>
            <div className='mt-3'>
              {props.prev !== "" ? <Link to={`/blog/${props.prev}`} replace className='float-start'><FiArrowLeft /> Prev</Link> : ""}
              {props.next !== "" ? <Link to={`/blog/${props.next}`} replace className='float-end'>Next <FiArrowRight /></Link> : ""}
            </div>
      </div>
    );
}

export default Post;