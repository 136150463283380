import { NavLink} from 'react-router-dom'
import Mockup from '../helpers/Mockup'

const Project = (props) => {
    const { title, images } = props.WorkData;

    var width=390;
    var height=260;
    var rx=8;
    var viewbox = "0 0 " + width + " " + height + " ";

    function project() {
        return <div className="col-md-4">
                    <NavLink to="/work">
                        <Mockup title={title} image={`${images.two_x}`} />
                    </NavLink>
                </div>
    }

    function cleanTitle(checkTitle) {
        checkTitle = checkTitle.replace("amp;", "");
        return checkTitle
    }

    return (
        project()
    );
}

export default Project;

